import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames, LIST_LAYOUT_TYPE} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import stylesParams from '../../../../stylesParams'
import {useWidgetState} from '../../../hooks/state-provider'
import {useEventsList} from '../../../hooks/use-events-list'
import {getCompId} from '../../../selectors/component'
import {focusFirstNewEvent} from '../../../utils/a11y'
import {CardContainer} from '../../event-container/card-container'
import {ListItemContainer} from '../../event-container/list-item-container'
import {LoadMoreButton} from '../../load-more-button'
import {MainListTitle} from '../../main-list-title'
import {DesktopItem} from '../item'
import {DesktopItem as OldDesktopItem} from '../item/old'
import {ListProps} from './interfaces'
import s from './list.scss'

export const List = ({t, narrow, mobile}: ListProps) => {
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const {experiments} = useExperiments()
  const {get} = useStyles()

  const compId = useWidgetState(getCompId)
  const eventsCount = events.length
  const listCards = get(stylesParams.listLayoutType) === LIST_LAYOUT_TYPE.CARDS
  const widgetPaddingSettingsEnabled = experiments.enabled(ExperimentNames.WidgetPaddingSettings)

  const style: React.CSSProperties = {
    padding: widgetPaddingSettingsEnabled ? undefined : narrow ? '0 20px' : '0 30px',
    overflowY: narrow && !mobile ? 'scroll' : 'hidden',
    height: narrow && !mobile ? '100%' : 'auto',
  }
  const className = classNames(s.container, s.scrollBarColor, {
    [s.listContainer]: experiments.enabled(ExperimentNames.MobileSettingsPanelRevamp) || !listCards,
    [s.padding]: widgetPaddingSettingsEnabled,
  })

  useEffect(() => {
    focusFirstNewEvent({eventsCount, pageLimit, hasMore, compId})
  }, [eventsCount])

  const Container = listCards ? CardContainer : ListItemContainer

  return (
    <div className={className} style={style} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) =>
          experiments.enabled(ExperimentNames.UpdatedListLayout) ? (
            <DesktopItem key={event.id} event={event} />
          ) : (
            <Container
              applyPadding
              key={event.id}
              event={event}
              showTopDivider={widgetPaddingSettingsEnabled && index === 0}
              hideDivider={!widgetPaddingSettingsEnabled && events.length - 1 === index && !hasMore}
              lastItem={events.length - 1 === index}
            >
              {hovered => <OldDesktopItem event={event} hovered={hovered} t={t} />}
            </Container>
          ),
        )}
      </ul>
      <div className={widgetPaddingSettingsEnabled ? null : s.loadMoreWrapper}>
        <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
      </div>
    </div>
  )
}
