import {getFormattedFullLocation, getFormattedLocation} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import s from './title-location.scss'

interface TitleProps {
  event: wix.events.Event
}

export const TitleLocation = ({event}: TitleProps) => {
  const {
    allBreakpoints: {isListShortLocationVisible, isListFullLocationVisible, isListLocationVisible},
  } = useVisibilityStyles()

  return (
    <div className={s.container}>
      <div className={s.title} data-hook="ev-list-item-title">
        {event.title}
      </div>
      {isListLocationVisible() && (
        <>
          <div className={classNames(s.divider, s.locationVisibility)} aria-hidden="true">
            &nbsp;/&nbsp;
          </div>
          <div className={classNames(s.location, s.locationVisibility)} data-hook="ev-list-item-location">
            {isListShortLocationVisible() && <span className={s.shortLocation}>{getFormattedLocation(event)}</span>}
            {isListFullLocationVisible() && <span className={s.fullLocation}>{getFormattedFullLocation(event)}</span>}
          </div>
        </>
      )}
    </div>
  )
}
