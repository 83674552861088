import {TFunction} from '@wix/yoshi-flow-editor'
import React from 'react'
import {SocialBar} from '../../../social-bar'
import s from './share.scss'

export interface ShareProps {
  t: TFunction
  event: wix.events.Event
}

export const Share = ({t, event}: ShareProps) => (
  <div className={s.container}>
    <SocialBar row t={t} spacing={24} event={event} />
  </div>
)
