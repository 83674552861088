import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useWidgetState} from '../../../hooks/state-provider'
import {getEvents} from '../../../selectors/events'
import {ListItemContainer} from '../../event-container/list-item-container'
import {DesktopItem} from '../item'
import {DesktopItem as OldDesktopItem} from '../item/old'

export const FeaturedList = () => {
  const {t} = useTranslation()
  const {experiments} = useExperiments()
  const [featuredEvent] = useWidgetState(getEvents)

  return experiments.enabled(ExperimentNames.UpdatedListLayout) ? (
    <DesktopItem event={featuredEvent} />
  ) : (
    <ListItemContainer event={featuredEvent} hideDivider>
      {hovered => <OldDesktopItem hovered={hovered} event={featuredEvent} t={t} />}
    </ListItemContainer>
  )
}
