import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {isNarrow} from '../../../selectors/list-settings'
import {getComponentConfig, isListLayout} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {ListStateProps} from './interfaces'
import {List as ListLayoutPresentation} from './list'

const mapRuntime = ({state, host: {dimensions}}: AppProps): ListStateProps => ({
  narrow: isNarrow(state, dimensions),
  mobile: isMobile(state),
  listLayout: isListLayout(getComponentConfig(state)),
})

export default connect<{}, ListStateProps>(mapRuntime)(withTranslation()(ListLayoutPresentation))
