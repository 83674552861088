import React from 'react'
import s from './description.scss'

interface DescriptionProps {
  event: wix.events.Event
}

export const Description = ({event}: DescriptionProps) =>
  event.description ? (
    <div className={s.description} data-hook="ev-list-item-description">
      {event.description}
    </div>
  ) : null
