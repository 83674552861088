import classNames from 'classnames'
import React from 'react'
import {ExpandArrow} from '../../../../../../../icons/ExpandArrow'
import s from './title.scss'

export interface TitleProps {
  event: wix.events.Event
  opened: boolean
  withOverflow?: boolean
}

export const Title = ({event, opened, withOverflow}: TitleProps) => {
  const classes = classNames(s.titleFont, {
    [s.overflow]: withOverflow,
  })

  return (
    <span className={classes} role="button" aria-expanded={opened}>
      <span className={s.title} data-hook="ev-list-item-title">
        {event.title}
      </span>
      <span className={classNames(s.expandArrow, {[s.reversed]: opened})}>
        <ExpandArrow />
      </span>
    </span>
  )
}
