import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import sc from '../../../classes.scss'
import s from './date.scss'

interface DateProps {
  customColorClass?: string
  event: wix.events.Event
  scheduleTbd: boolean
}

export const Date = ({scheduleTbd, event}: DateProps) => {
  const {
    allBreakpoints: {isListDateVisible, isListShortDateLocationVisible, isListFullDateLocationVisible},
  } = useVisibilityStyles()
  const {shortStartDate, fullDate} = useEventDateInformation(event.id)

  if (!isListDateVisible()) {
    return null
  }

  return (
    <div
      className={classNames(s.root, sc.textNoMargin, s.dateFont)}
      data-hook={scheduleTbd ? 'ev-date-tbd' : 'ev-date'}
    >
      {isListShortDateLocationVisible() && <span className={s.shortDateVisibility}>{shortStartDate}</span>}
      {isListFullDateLocationVisible() && <span className={s.fullDateVisibility}>{fullDate}</span>}
    </div>
  )
}
